import React from "react";
import expImg from "../../../../assets/images/porfil-experience-test.svg";
import dayjs from "dayjs";
import { getEmploymentType } from "../../../../utils/helper";
import ExperienceImage from "./ExperienceImage";

const ProfileExperience = ({ profileData }) => {
  return (
    <div className="profileExperience">
      {profileData?.experiences?.map((item, index) => (
        <div className="expCard" key={index}>
          <div className="top">
            <div className="expImg">
              <ExperienceImage char={item.title.slice(0, 1)} />
            </div>
            <div className="info">
              <p className="title">{item?.title}</p>
              <div className="subTitle">
                <span>{item?.company}</span>
                <span className="dot">•</span>
                <span>{getEmploymentType(item?.employment_type)}</span>
              </div>
            </div>
          </div>
          <div className="bottom">
            <p className="dates">
              {dayjs(item?.start_date).format("DD.MM.YYYY")} -{" "}
              {item?.still_working_there
                ? "Davam edir"
                : dayjs(item?.end_date).format("DD.MM.YYYY")}
              {item?.start_date &&
                item?.end_date &&
                !item?.still_working_there && (
                  <span>
                    {" "}
                    •{" "}
                    {Math.floor(
                      (new Date(item.end_date) - new Date(item.start_date)) /
                        (1000 * 60 * 60 * 24 * 365)
                    )}{" "}
                    il
                  </span>
                )}
            </p>
            <p>{item?.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProfileExperience;
