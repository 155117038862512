import {yupResolver} from "@hookform/resolvers/yup";
import {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {object, string} from "yup";
// import building from "../../../assets/images/icons/building.svg";
import chevronDownWhite from "../../../assets/images/icons/chevron-white.svg";
import logOutIcon from "../../../assets/images/icons/logout.svg";
import searchIcon from "../../../assets/images/icons/search-icon.svg";
import searchLogo from "../../../assets/images/logo-search.svg";
import MobileNavBar from "../../../components/MobileNavBar";
import searchKeywords from "../../../db/searchKeywords";
import {LoginContext} from "../../../utils/Auth";
import linkIcon from "../../../assets/images/icons/link-icon-white.svg";
import {useUserLogOut} from "../../Auth/Service/AuthService";
import {MainContext} from "../../../utils/MainContext";
import SharedProfileModal from "../../../components/SharedProfileModal";
import placeholders from "../../../db/searchPlaceholder";
import noUserImage from "../../../assets/images/icons/no-user-image.svg"
import { PROFILE_TYPES } from "../../Profile/Service/ProfileService";
const Search = () => {
  // Translation
  const { t } = useTranslation();

  // router
  const navigate = useNavigate();

  // Custom hook
  const userLogOut = useUserLogOut();

  // Global states
  const { user } = useContext(LoginContext);
  const { setSharedProfileModal,setMySelfProfilView,setDashboardPage } = useContext(MainContext);

  // Local states
  const [openMenu, setOpenMenu] = useState(false);

  // yup
  const searchSchema = object({
    searchValue: string()
      .required(t("search.errorMsg1"))
      .max(50, t("search.errorMsg2")),
  });

  // Hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchSchema),
  });

  // Navigate search result page
  const onSubmit = (data) => {
    navigate(`/search-result?filter=${data.searchValue}`);
  };

  const logOut = () => {
    userLogOut();
  };
  console.log(placeholders[0].title)

  return (
    <main>
      <section className="searchPage">
        <div
          onClick={() => setOpenMenu(false)}
          className={`outsildeClickOverlay ${openMenu && "active"}`}
        ></div>
        <div className="sectionHeader">
          <div className="topSide">
            <div className="logo">
              <Link to="/dashboard">
                <img src={searchLogo} alt="search-logo" />
              </Link>
            </div>
            <div className="account">
              <div
                className="shareIcon"
                onClick={() => setSharedProfileModal(true)}
              >
                <div className="icon">
                  <img src={linkIcon} alt="link-icon" />
                </div>
                <p className="shareInfo">{t("dashboardHeader.share")}</p>
              </div>
              <p
                className={openMenu ? "title up" : "title"}
                onClick={() => setOpenMenu(!openMenu)}
              >
                {user?.full_name}
                <img src={chevronDownWhite} alt="chevron-icon" />
              </p>
              <ul className={openMenu ? "accountMenu open" : "accountMenu"}>
              <li className="accountMenuItem">
                <Link
                  to={
                    user?.profile?.profile_type !== PROFILE_TYPES.free &&
                    `/profile/${user?.username}`
                  }
                  onClick={() => {
                    setMySelfProfilView(false);
                    setDashboardPage("upgrade-pack");
                  }}
                >
                  <img
                    src={
                      user?.profile?.avatar
                        ? user?.profile?.avatar
                        : noUserImage
                    }
                    alt="medal-icon"
                    className="medalIcon"
                  />
                  {user?.full_name}
                </Link>
              </li>
                <li className="accountMenuItem" onClick={logOut}>
                  <Link to="#">
                    <img src={logOutIcon} alt="logout-icon" />
                    {t("search.signOut")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottomSide">
            <h2 className="title">{t("search.find")}</h2>
          </div>
        </div>
        <div className="sectionBody">
          <form
            className={`searchForm ${errors.searchValue && "isError"}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <label htmlFor="bucardSearch">
              <img src={searchIcon} alt="search-icon" />
            </label>
            <input
              type="text"
              name="bucardSearch"
              id="bucardSearch"
              placeholder={placeholders[Math.floor(Math.random() * 7)].title}
              // placeholder={t("search.placeholder")}
              className="searchInput"
              {...register("searchValue")}
            />
            <button className="searchBtn">{t("search.btnValue")}</button>
          </form>
          <span className={`errorMessage ${errors.searchValue && "isError"}`}>
            {errors?.searchValue?.message}
          </span>
          <ul className="keywordList">
            {searchKeywords.map((item) => (
              <li
                className="keyword"
                key={item.id}
                onClick={() =>
                  navigate(`/search-result?filter=${item.keyword}`)
                }
              >
                {item.keyword}
              </li>
            ))}
          </ul>
        </div>
        <MobileNavBar />
        <SharedProfileModal data={user} />
      </section>
    </main>
  );
};

export default Search;
