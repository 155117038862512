import { useTranslation } from "react-i18next";
import { useState } from "react";
import ButtonsGroup from "../../../components/ButtonsGroup";
import UserIcon from "../../../assets/images/icons/userIcon.svg";
import UsersIcon from "../../../assets/images/icons/usersIcon.svg";
import CompanyIcon from "../../../assets/images/icons/companyIcon.svg";
import CheckBox from "../../../assets/images/checkboxIcon.svg";
import WharIsBucard from "../../../assets/images/whatIsBucard.png";
import BiznesBucard from "../../../assets/images/biznesBucard.png";
import CheckIcon from "../../../assets/images/icons/checkIcon.svg";
import { changeLanguage } from "i18next";
const ChangePlan = () => {
  // Translation
  const { t } = useTranslation();

  // Local state
  const [businessPlan, setBusinessPlan] = useState(false);

  return (
    <section className="changePlan" id="whatIsBucard">
      <div className="row">
        <div className="whatIsBucard">
          <div className="whatIsInfo">
            <h4>{t("changePlan.question")}</h4>
            <p>{t("changePlan.personalInfo2")}</p>
            <p>{t("changePlan.businessInfo2")}</p>
            <p className="infoIcon">
              <img src={CheckBox} alt="check-box" />
              {t("changePlan.personalInfo1")}
            </p>
            <p className="infoIcon">
              <img src={CheckBox} alt="check-box" />
              {t("changePlan.personalInfo3")}
            </p>
            <p className="infoIcon">
              <img src={CheckBox} alt="check-box" />
              {t("changePlan.personalInfo4")}
            </p>
            <p className="infoIcon">
              <img src={CheckBox} alt="check-box" />
              {t("changePlan.personalInfo6")}
            </p>
            <p className="infoIcon">
              <img src={CheckBox} alt="check-box" />
              {t("changePlan.personalInfo7")}
            </p>
          </div>
          <div className="whatIsImg">
            <img src={WharIsBucard} alt="whatIsBucard" />
          </div>
        </div>
        <div className="bucardBiznes">
          <div className="biznesImg">
            <img src={BiznesBucard} alt="biznes-bucard" />
          </div>
          <div className="biznesInfo">
            <h4>{t("changePlan.question")}</h4>

            <p>{t("changePlan.personalInfo2")}</p>
            <p>{t("changePlan.businessInfo2")}</p>
            <p className="infoIcon">
              <img src={CheckIcon} alt="check-box" />
              {t("changePlan.personalInfo8")}
            </p>
            <p className="infoIcon">
              <img src={CheckIcon} alt="check-box" />
              {t("changePlan.personalInfo9")}
            </p>
            <p className="infoIcon">
              <img src={CheckIcon} alt="check-box" />
              {t("changePlan.personalInfo10")}
            </p>
            <p className="infoIcon">
              <img src={CheckIcon} alt="check-box" />
              {t("changePlan.personalInfo11")}
            </p>
          </div>
        </div>
        {/* <div className="leftSide">
                    <h2 className="tabTitle">{t("changePlan.question")}</h2>
                    <ButtonsGroup state={{businessPlan, setBusinessPlan}}/>
                    <ul className={`tabList ${!businessPlan && "active"}`}>
                        <li className="tabItem">{t("changePlan.personalInfo1")}</li>
                        <li className="tabItem">{t("changePlan.personalInfo2")}</li>
                        <li className="tabItem">{t("changePlan.personalInfo3")}</li>
                        <li className="tabItem">{t("changePlan.personalInfo4")}</li>
                        <li className="tabItem">{t("changePlan.personalInfo5")}</li>
                    </ul>
                    <ul className={`tabList ${businessPlan && "active"}`}>
                        <li className="tabItem">{t("changePlan.businessInfo1")}</li>
                        <li className="tabItem">{t("changePlan.businessInfo2")}</li>
                        <li className="tabItem">{t("changePlan.businessInfo3")}</li>
                        <li className="tabItem">{t("changePlan.businessInfo4")}</li>
                        <li className="tabItem">{t("changePlan.businessInfo5")}</li>
                    </ul>
                </div>
                <div className={`rightSide ${businessPlan && "active"}`}></div> */}
      </div>
    </section>
  );
};

export default ChangePlan;
