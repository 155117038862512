import {yupResolver} from "@hookform/resolvers/yup";
import {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {object, string} from "yup";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import {MainContext} from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";

const ForgotOtpStep = () => {
  // Translation
  const { t } = useTranslation();

  // Router
  const navigate = useNavigate();

  // Global states
  const { phone, setForgotStep, setStep } = useContext(MainContext);

  // Local states
  const [seconds, setSeconds] = useState(120);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const [resendLimit, setResendLimit] = useState(3);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // For countdown timer
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          setIsCountdownOver(true);
          return prevSeconds;
        }
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Yup otp schema
  const otpSchema = object({
    otp: string()
      .required(t("registerStepThree.errorMsg1"))
      .min(4, t("registerStepThree.errorMsg2"))
      .matches(/^\d{4}$/, t("registerStepThree.errorMsg3")),
  });

  // React Hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(otpSchema),
  });

  // On submit otp
  const onSubmit = async (data) => {
    setLoading(true);
    const body = {
      phone_number: phone,
      otp: data.otp,
    };
    if (!isSubmitting) {
      setIsSubmitting(true);

      try {
        const res = await AuthService.confirmResetPassword(body);
        if (res.session_key) {
          setStep(1);
          setForgotStep(1);
          navigate(`/recover-password/${phone}/${res.session_key}`);
        } else {
          setErrorMsg("OTP təsdiqlənməsi uğursuz oldu !!!");
        }
      } catch (err) {
        setErrorMsg("OTP təsdiqlənməsi uğursuz oldu !!!");
      } finally {
        setLoading(false);
        setIsSubmitting(false);
      }
    }
  };

  // Resend otp code when time finished
  const reSendOtp = async () => {
    setLoading(true);
    const body = {
      phone_number: phone,
    };
    try {
      await AuthService.resendOtp(body);
      setResendLimit(resendLimit - 1);
      setSeconds(120);
      setIsCountdownOver(false);
    } catch (err) {
      console.log(err);
      setErrorMsg(err.response.data);
    } finally {
      setLoading(false);
    }
  };

  // Limit is equal to zero, reload page.
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loading />}
      <h2 className="formTitle">
        {errorMsg ? t("forgotPassword.atention") : t("registerStepThree.formTitle")}
      </h2>
      {isCountdownOver
        ? !errorMsg && (
            <p className="question">
              {resendLimit === 0
                ? t("registerStepThree.limit")
                : t("registerStepThree.time")}
              <span
                onClick={resendLimit === 0 ? reloadPage : reSendOtp}
                className="reSend"
              >
                {resendLimit === 0
                  ? t("registerStepThree.again")
                  : t("registerStepThree.send")}
              </span>
            </p>
          )
        : !errorMsg && (
            <p className="question">
              {t("registerStepThree.info")} <span>{phone}</span> <br />{" "}
              {t("registerStepThree.resend")}
              <span className="timer">
                {minutes}:{remainingSeconds < 10 ? "0" : ""}
                {remainingSeconds}
              </span>
              {t("registerStepThree.left")}
            </p>
          )}
      <div className="inputBox">
        {!errorMsg && (
          <input
            type="text"
            className={errors.otp || errorMsg ? "formInput error" : "formInput"}
            name="otp"
            maxLength="4"
            {...register("otp")}
          />
        )}
        {errors.otp && <span className="errorAlert">{errors.otp.message}</span>}
        {errorMsg && <span className="errorAlert">{errorMsg}</span>}
        {errorMsg && (
          <p className="regError">
            <Link to="/">{t("forgotPassword.goBack")}</Link>
          </p>
        )}
      </div>
      {!errorMsg && (
        <Button
          className="formBtn"
          type="submit"
          disabled={!!errors.otp || isCountdownOver}
        >
          {t("registerStepThree.btnValue")}
        </Button>
      )}
    </form>
  );
};

export default ForgotOtpStep;
