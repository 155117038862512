import React, { useState } from "react";
import * as ProfileService from "../../Service/ProfileService";
// import CustomInput from "../../../../components/CustomInput";
import OtpForm from "../../../../components/OtpForm";
import Spinner from "../../../../components/Spinner";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { set } from "react-hook-form";

const ProfilePhoneNumbersEdit = ({
  profileData,
  closeModal,
  setProfileData,
}) => {
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    extra_phone_number: profileData?.extra_phone_numbers || [],
    phone_number:profileData?.phone_number || [],
  });



  const [newPhoneNumber, setNewPhoneNumber] = useState({
    phone_number: "",
    isVerified: false,
  });



  const [isEditPrimaryPhone, setIsEditPrimaryPhone] = useState(false);

  const { t } = useTranslation();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (newPhoneNumber.isVerified) {
        await ProfileService.profileEdit(profileInfo);
        closeModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    const phoneNumber = value?.trim();
    setNewPhoneNumber({ phone_number: phoneNumber });

    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, "AZ");
    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
      setErrorMessage("");
    } else {
      setErrorMessage("Nömrə düzgün deyil");
    }
  };

  const verifyPhone = async () => {
    const body = {
      extra_phone_number: `${newPhoneNumber.phone_number}`,
    };
    try {
      if (body.extra_phone_number !== "") {
        await ProfileService.profileEdit({
          extra_phone_number: [
            { phone_number: `${newPhoneNumber.phone_number}` },
          ],
        });
        await ProfileService.sendOtpToPhone(body);
        setShowOtpModal(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  const editMainPhoneNumber = async () =>{
    if(!newPhoneNumber.phone_number || errorMessage){
      return;
    }
    setLoading(true)
    const body = {
      phone_number:`${newPhoneNumber.phone_number}`
    };
    try {
      await ProfileService.profileEdit({phone_number:body.phone_number})
      await ProfileService.sendOtpToPhone(body);
      setShowOtpModal(true);
    } catch (error) {
      console.log("Error editing main phone number:",error);
      
    }finally{
      setLoading(false)
    }
  }

  const handleOtpVerified = async (verified) => {
    if (verified) {
      try {
        await ProfileService.profileEdit({
          phone_number: `+994${newPhoneNumber.phone_number}`,
        });
  
        // Update the profile data with the new phone number
        setProfileData((prev) => ({
          ...prev,
          phone_number: newPhoneNumber.phone_number,
        }));
  
        closeModal(); // Close the modal after updating the number
        window.location.reload(); // Optional: reload to reflect changes
      } catch (error) {
        console.error("Error updating phone number after OTP verification:", error);
      }
    }
  };



  const removePhone = async (phone) => {
    Swal.fire({
      title: "Nömrəni silmək",
      text: "Nömrə silinsin?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli",
      cancelButtonText: "Xeyr",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "extra_phone_number",
            id: phone.id,
          });
          setProfileData((prev) => ({
            ...prev,
            extra_phone_numbers: res.data.extra_phone_numbers,
          }));
          setProfileInfo((prev) => ({
            ...prev,
            extra_phone_number: res.data.extra_phone_numbers,
          }));
          Swal.fire("Uğurlu", "Nömrə silindi", "success");
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        Swal.fire("İmtina edildi", "Nömrəni silməkdən imtina edildu", "error");
      }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return showOtpModal ? (
    <OtpForm
      onSubmit={(verified) => {
        if (verified) {
          closeModal();
          window.location.reload();
        }
      }}
      phone={`${newPhoneNumber.phone_number}`}
    />
  ) : (
    <div className="profilePhoneEdit">
      <div className="editPhoneArea">
        {profileInfo.extra_phone_number.map((phone) => (
          <button
            key={phone.id}
            className="phoneCaption"
            onClick={() => removePhone(phone)}
          >
            {phone.phone_number}
            <span>x</span>
          </button>
        ))}
      </div>
      <form className="infoPhoneForm">
        {/* <CustomInput
          label={"Yeni nömrə"}
          placeholder="+994 000 00 00"
          maxLength={15}
          type={"text"}
          errorMessage={errorMessage}
          value={newPhoneNumber.phone_number}
          onChange={(value) => {
            const phoneNumber = value?.trim();
            setNewPhoneNumber({ phone_number: phoneNumber });

            const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, "AZ");
            if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
              setErrorMessage("");
            } else {
              setErrorMessage("Nömrə düzgün deyil");
            }
          }}
        /> */}
 <div className="phoneBox">
  {/* <label htmlFor="phone">{t("login.phone")} *</label> */}
  {/* <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    <PhoneInput
      international
      defaultCountry="AZ"
      value={profileData.phone_number}
      disabled={!isEditPrimaryPhone}
      onChange={handleChange}
      limitMaxLength={19}
      style={{ opacity: isEditPrimaryPhone ? 1 : 0.5, width: "80%" }}
    />
    <span
      style={{ fontSize: 14, color: "#115eed", cursor: "pointer" }}
      onClick={() => setIsEditPrimaryPhone(!isEditPrimaryPhone)}
    >
      {isEditPrimaryPhone ? "İmtina" : "Düzəliş et"}
    </span>
  </div> */}

  <label htmlFor="phone">{t("login.phone")} *</label>
  <PhoneInput
    international
    defaultCountry="AZ"
    value={newPhoneNumber?.phone_number}
    onChange={handleChange}
    limitMaxLength={19}
    disabled={isEditPrimaryPhone}  
    style={{ opacity: isEditPrimaryPhone ? 0.5 : 1 }}
  />
</div>

      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          İmtina
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={newPhoneNumber.isVerified ? onSubmit : verifyPhone}
          disabled={errorMessage !== ""}
        >
          Yadda saxla
        </button>
      </div>
    </div>
  );
};

export default ProfilePhoneNumbersEdit;
