import React from "react";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";
import { Link } from "react-router-dom";
import { t } from "i18next";

const ContactItem = ({
  title,
  data,
  icon,
  mainContact,
  type,
  onEdit,
  showContactEdit,
  isOwner,
}) => {
  return (
    <div className="contactItem">
      <div className="cardHead">
        <div className="cardTitle">
          <img src={icon} alt="phone_icon" />
          <span>{title}</span>
        </div>
        {showContactEdit && isOwner && (
          <div className="editIcon" onClick={() => onEdit()}>
            <img src={PenIcon} alt="pen_icon" />
          </div>
        )}
      </div>
      <div className="cardBody">
        {mainContact && (
          <div className="contact">
            <Link to={`${type}:${mainContact}`}>{mainContact}</Link>
            <span className="badge">{t("profileHero.main")}</span>
          </div>
        )}
        <div className="contact list">
          {data?.length !== 0 &&
            data?.map((item) => (
              <Link
                to={`${type}:${
                  type === "mailto" ? item.email : item.phone_number
                }`}
                key={item.id}
              >
                {type === "mailto" ? item.email : item.phone_number}
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
