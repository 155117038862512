import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "./Modules/Additionals/View/NotFound";
import SelectPack from "./Modules/Additionals/View/SelectPack";
import SharedUserProfile from "./Modules/Additionals/View/SharedUserProfile";
import ForgotPassword from "./Modules/Auth/View/ForgotPassword";
import Login from "./Modules/Auth/View/Login";
import RecoverPassword from "./Modules/Auth/View/RecoverPassword";
import Register from "./Modules/Auth/View/Register";
import SendLink from "./Modules/Auth/View/SendLink";
import BoxDetails from "./Modules/Dashboard/View/BoxDetails";
import CompanyPage from "./Modules/Dashboard/View/CompanyPage";
import Dashboard from "./Modules/Dashboard/View/Dashboard";
import Home from "./Modules/Home/View/Home";
import OrderBucard from "./Modules/OrderBucard/View/OrderBucard";
import Profile from "./Modules/Profile/View/Profile";
import Search from "./Modules/Search/View/Search";
import SearchResult from "./Modules/Search/View/SearchResult";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Auth } from "./utils/Auth";
import { AppContext } from "./utils/MainContext";
import useShowHeaderFooter from "./utils/useShowHeaderFooter";
import Settings from "./Modules/Settings/View/Settings";
import General from "./Modules/Settings/View/General";
import PaymentInfo from "./Modules/UpgradePack/View/PaymentInfo";

export const isDevelop = process.env.NODE_ENV === "development";

const App = () => {
  const pageUrl = useLocation();

  return (
    <Auth>
      <AppContext>
        {useShowHeaderFooter(pageUrl) && <Header />}
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/search" element={<Search />} />
          <Route path="/search-result" element={<SearchResult />} />
          <Route path="/box-details/:boxID" element={<BoxDetails />} />
          <Route path="/company-page/:companyID" element={<CompanyPage />} />
          <Route path="/order-bucard" element={<OrderBucard />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="/qr-scanner" element={<QRCodeScanner />} /> */}
          <Route path="/profile/:username" element={<Profile />} />
          <Route
            path="/shared-user/:username"
            element={<SharedUserProfile />}
          />
          {/* <Route path="/settings" element={<Settings />} />
            <Route path="/settings/general" element={<General />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/send-link" element={<SendLink />} />
          <Route
            path="/recover-password/:phone/:key"
            element={<RecoverPassword />}
          />
          <Route
            path="/select-pack/:userId/:profile_type"
            element={<SelectPack />}
          />
          <Route path="/payment-info/:type" element={<PaymentInfo />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {useShowHeaderFooter(pageUrl) && <Footer />}
      </AppContext>
    </Auth>
  );
};

export default App;
