import React, { useEffect, useRef, useCallback, useState } from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import { toastMsg } from "../../../../utils/swal";
import MapAutocomplete from "./MapAutocomplete";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const isDevelop = process.env.NODE_ENV === "development";

const libraries = ["places"];
const googleMapsApiKey = isDevelop
  ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEVELOP
  : process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PROD;

const MapComponent = ({ onAddressSelect, value, cordinates, onClickBack }) => {
  const inputRef = useRef();
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: null, lng: null }); // Use state for center
  const [geocoding, setGeocoding] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    if (cordinates?.lat) {
      setGeocoding(cordinates);
    }
  }, [cordinates]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
    libraries,
  });

  const onLoad = useCallback(
    (mapInstance) => {
      if (center.lat !== null && center.lng !== null) {
        const bounds = new window.google.maps.LatLngBounds(center);
        mapInstance.fitBounds(bounds);
      }
      setMap(mapInstance);
    },
    [center]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const newCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(newCenter);
        },
        function (error) {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              toastMsg("Kullanıcı konum iznini reddetti.");
              break;
            case error.POSITION_UNAVAILABLE:
              toastMsg("Konum bilgisi mevcut değil.");
              break;
            case error.TIMEOUT:
              toastMsg("İstek zaman aşımına uğradı.");
              break;
            case error.UNKNOWN_ERROR:
              toastMsg("Bilinmeyen bir hata oluştu.");
              break;
          }
        }
      );
    } else {
      toastMsg("Tarayıcınız konum hizmetlerini desteklemiyor.");
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  return isLoaded && center.lat !== null && center.lng !== null ? (
    <div key={isLoaded}>
      <MapAutocomplete
        key={center}
        style={{ marginBottom: 16 }}
        onAddressSelected={(selected) => {
          const { formatted_address, city, country, url, latitude, longitude } =
            selected;
          const newLocation = {
            lat: Number(latitude),
            lng: Number(longitude),
          };
          setCenter(newLocation);
          onAddressSelect({
            address: formatted_address,
            city,
            country,
            url,
            latitude,
            longitude,
          });
        }}
        value={value}
        geocoding={geocoding}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={(e) => {
          const newLocation = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
          };
          setCenter(newLocation);
          setGeocoding(newLocation);
        }}
      >
        <Marker
          draggable={true}
          position={center}
          onDragEnd={(e) => {
            const newLocation = {
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            };
            setCenter(newLocation);
            setGeocoding(newLocation);
          }}
        />
      </GoogleMap>
      <button
        style={{ marginTop: 16 }}
        className="primaryBtn"
        onClick={onClickBack}
      >
        Geri
      </button>
    </div>
  ) : (
    <div>Xəritə hazırlanır...</div>
  );
};

export default React.memo(MapComponent);
