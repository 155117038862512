import React, { useEffect, useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import addCircle from "../../../../assets/images/icons/add-circle.svg";
import { useDebounce } from "use-debounce";

const ProfileFeaturedEdit = ({
  profileData,
  setModalData,
  setProfileData,
  data,
  closeModal,
}) => {
  const [url, setUrl] = useState(profileData?.url || "");
  const [delayedUrl] = useDebounce(url, 500);
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    title: profileData?.title || "",
    description: profileData?.description || "",
    url: url,
    image_url: profileData.image_url || "",
  });

  const buttonDisabled =
    profileInfo.description === "" ||
    profileInfo.title === "" ||
    profileInfo.url === "" ||
    profileInfo.image_url === "";

  useEffect(() => {
    if (data) {
      setProfileInfo(data);
      setUrl(data.url);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit({
        featured_link: profileInfo,
      });
      setProfileData(res);
      closeModal();
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  // Continue from here

  const urlMetaData = async (url) => {
    try {
      const res = await ProfileService.urlMetaDatas({ url });
      setProfileInfo((prev) => ({
        ...prev,
        image_url:
          res.thumbnail !== null
            ? res.thumbnail
            : "https://bucard.az",
        url,
      }));
    } catch (err) {
      console.log("Error fetching URL metadata", err);
    }
  };

  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.([a-z]{2,})|localhost)|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(url);
  };

  useEffect(() => {
    if (delayedUrl && isValidUrl(delayedUrl)) {
      urlMetaData(delayedUrl);
    }
  }, [delayedUrl]);

  return (
    <div className="profileFeaturedEdit">
      <form className="infoEditForm" onSubmit={onSubmit}>
        <CustomInput
          label="Başlıq"
          placeholder="Başlıq daxil edin"
          onChange={(value) =>
            setProfileInfo((prev) => ({ ...prev, title: value }))
          }
          value={profileInfo.title}
        />
        <CustomInput
          label="Açıqlama"
          placeholder="Açıqlama əlavə edin"
          onChange={(value) =>
            setProfileInfo((prev) => ({ ...prev, description: value }))
          }
          value={profileInfo.description}
        />
        <CustomInput
          label="Link"
          placeholder="Link daxil edin https://..."
          onChange={(value) => setUrl(value)}
          value={url}
        />
        <div className="btnGroup">
          {/* <div className="addBtn">
            <button
              className="btnAdd"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onAdd();
              }}
            >
              <img src={addCircle} alt="Add new" />
              Əlavə et
            </button>
          </div> */}
          <button
            className="btn cancel"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            İmtina
          </button>
          <button className="btn" type="submit" onClick={onSubmit}>
            {loading ? "Gözləyin..." : "Yadda saxla"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileFeaturedEdit;
