import React, { useEffect, useState } from "react";
import EditExperience from "../../../../assets/images/icons/editExperience.svg";
import ExampleCompany from "../../../../assets/images/icons/exampleCompany.svg";
import addCircle from "../../../../assets/images/icons/add-circle.svg";
import { getEmploymentType } from "../../../../utils/helper";
import dayjs from "dayjs";
import { confirmSwal } from "../../../../utils/swal";
import * as ProfileService from "../../Service/ProfileService";
import ExperienceImage from "./ExperienceImage";

const ProfileEditExperience = ({
  onAdd,
  setProfileData,
  closeModal,
  profileData,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(profileData.experiences);
  }, [profileData.experiences]);

  const removeExperience = (item) => {
    confirmSwal(
      item.title,
      "Silmək istədiyinizə əminsinizmi ?",
      "Bəli",
      "Xeyr",
      () => {},
      async () => {
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "experience",
            id: item.id,
            
          });
          setProfileData((prev) => ({
            ...prev,
            experiences: res.data.experiences,
          }));
          setData((prev) => prev.filter((exp) => exp.id !== item.id));
        } catch (err) {
          console.log(err);
        }
      },
      "Silinmə tamamlandı",
      "İmtina"
    );
  };

  return (
    <div className="editExperience">
      {/* <div className="experienceTitle">
        <p className="experienceInfo">Experience</p>
        <button className="editExperienceBtn">
          <img src={EditExperience} alt="" />
        </button>
      </div> */}

      <div
        className="profileExperience"
        style={{ maxHeight: 400, overflowY: "auto", padding: "0 16px" }}
      >
        {data?.length !== 0 &&
          data?.map((item) => (
            <div className="expCard">
              <div className="top">
                <div className="expImg">
                  <ExperienceImage char={item.title.slice(0, 1)} />
                </div>
                <div className="info">
                  <div className="editPosition">
                    <p className="title">{item.title}</p>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 16 }}
                    >
                      <button
                        className="removeBtn"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          removeExperience(item);
                        }}
                      >
                        Sil
                      </button>
                      <button
                        className="btnAdd"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          onAdd(item);
                        }}
                      >
                        Düzəliş et
                      </button>
                    </div>
                  </div>
                  <div className="subTitle">
                    <span>{item.company}</span>
                    <span className="dot">•</span>
                    <span>{getEmploymentType(item.employment_type)}</span>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <p className="dates">
                  {dayjs(item?.start_date).format("DD.MM.YYYY")} -{" "}
                  {item?.still_working_there
                    ? "Davam edir"
                    : dayjs(item?.end_date).format("DD.MM.YYYY")}
                  {item?.start_date &&
                    item?.end_date &&
                    !item?.still_working_there && (
                      <span>
                        {" "}
                        •{" "}
                        {Math.floor(
                          (new Date(item.end_date) -
                            new Date(item.start_date)) /
                            (1000 * 60 * 60 * 24 * 365)
                        )}{" "}
                        il
                      </span>
                    )}
                </p>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
      </div>

      <div className="btnGroup">
        <div className="addBtn">
          <button
            className="btnAdd"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onAdd();
            }}
          >
            <img src={addCircle} alt="" />
            Əlavə et
          </button>
        </div>
        <button
          className="btn"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          Bağla
        </button>
        {/* <button className="btn">Yadda saxla</button> */}
      </div>
    </div>
  );
};

export default ProfileEditExperience;
