import {yupResolver} from "@hookform/resolvers/yup";
import {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {object, string} from "yup";
import eyeSlash from "../../../assets/images/icons/eye-slash.svg";
import eye from "../../../assets/images/icons/eye.svg";
import ErrorLabel from "../../../components/ErrorLabel";
import Loading from "../../../components/Loading";
import {MainContext} from "../../../utils/MainContext";
import * as AuthService from "../Service/AuthService";
import { LoginContext } from "../../../utils/Auth";

const StepThree = () => {
  // Translation
  const { t } = useTranslation();

  const navigate = useNavigate();

  // Global states

  const { setStep } = useContext(MainContext);
  const { setIsUserIn } = useContext(LoginContext);

  // Local states
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // Yup schema
  const userSchema = object({
    fullName: string()
      .required(t("registerStepTwo.errorMsg1"))
      .min(3, t("registerStepTwo.errorMsg2"))
      .max(53, t("registerStepTwo.errorMsg3")),
    // username: string()
    //   .required()
    //   .matches(/^[a-zA-Z0-9_.]{3,20}$/, t("registerStepThree.errorMsg4")),
    password: string()
      .required(t("login.errorMsg1"))
      .trim()
      .min(8, t("login.errorMsg2")),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  // Form on submit
  const onSubmit = async (data) => {
    setLoading(true);
    const body = {
      full_name: data?.fullName,
      // username: data?.username,
      password: data?.password,
    };

    try {
      const res = await AuthService.activateUser(body);
      console.log('res',res)
      // const response = await AuthService.updateUsername({
      //   username: data?.username,
      // });
      navigate(
        `/select-pack/${res?.user?.profile?.id}/${res?.user?.profile?.profile_type}`
      );
      setIsUserIn(true)
      navigate("/dashboard")
      setStep(1);
    } catch (err) {
      console.log(err);
      setErrorMsg(err?.response?.data?.phone_number);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="registerForm" onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loading />}
      <h2 className="formTitle">{t("registerStepTwo.formTitle")}</h2>
      <p className="question">{t("registerStepTwo.info")}</p>
      <div className="inputBox">
        <label htmlFor="name">{t("registerStepTwo.fullName")} *</label>
        <input
          type="text"
          className={errors.fullName ? "formInput err" : "formInput"}
          id="name"
          name="fullName"
          placeholder="Tarlan Alijanov"
          {...register("fullName")}
        />
        {errors.fullName && (
          <span className="errorAlert">{errors.fullName.message}</span>
        )}
      </div>
      {/* <div className="inputBox">
        <label htmlFor="username">İstifadəçi adı *</label>
        <input
          type="text"
          className={errors.fullName ? "formInput err" : "formInput"}
          id="username"
          name="username"
          placeholder="myusername"
          {...register("username")}
        />
        {errors.username && (
          <span className="errorAlert">{errors.username.message}</span>
        )}
      </div> */}
      <div className="inputBox">
        <label htmlFor="password">{t("registerStepTwo.pass")} *</label>
        <input
          type={showPassword ? "text" : "password"}
          className={errors.password ? "formInput err" : "formInput"}
          id="password"
          name="password"
          placeholder="********"
          {...register("password")}
        />
        {errors.password && (
          <span className="errorAlert">{errors.password.message}</span>
        )}
        <div
          className="eye"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <img
            src={showPassword ? eyeSlash : eye}
            alt={showPassword ? "Hide password" : "Show password"}
          />
        </div>
      </div>
      {errorMsg && <ErrorLabel text={errorMsg} />}
      <button
        className="formBtn"
        type="submit"
        disabled={!!(errors.password || errors.fullName)}
      >
        {t("registerStepTwo.btnValue")}
      </button>
    </form>
  );
};

export default StepThree;
